.res_card {
  width: 354px;
  min-height: 288px;
  border-radius: 24px;
}

.title {
  font-size: 20px;
  line-height: 28px;
  max-width: 323px;
}

.tags {
  font-size: 14px;
  line-height: 20px;
}

.res_image {
  height: 162px;
  width: 100%;
  overflow: hidden;
}

.dietary_tag {
  font-size: 12px;
}

@media (max-width: 1150px) {
  .res_card {
    width: 332px;
    min-height: 288px;
    border-radius: 24px;
  }

  .title {
    font-size: 18px;
    line-height: 28px;
    max-width: 300px;
  }

  .tags {
    font-size: 13px;
    line-height: 20px;
  }

  .res_image {
    height: 162px !important;
    width: 100%;
    overflow: hidden;
  }
}

@media (max-width: 480px) {
  .res_card {
    width: 288px;
    min-height: 288px;
    border-radius: 24px;
  }

  .title {
    font-size: 20px;
    line-height: 28px;
    max-width: 262px;
  }

  .tags {
    font-size: 14px;
    line-height: 20px;
  }

  .res_image {
    height: 162px !important;
  }

  .dietary_tag {
    font-size: 12px;
  }
}
