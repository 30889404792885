.timepicker-ui-wrapper {
  height: 550px !important;
  width: 305px !important;
}

.timepicker-ui-wrapper.mobile {
  height: 218px !important;
  width: 305px !important;
}

.timepicker-ui-wrapper-type-time.mobile {
  height: 70px !important;
}

.timepicker-ui-wrapper-type-time {
  height: 100px !important;
}

.font-inter-regular {
  font-family: Inter-Regular;
}

.font-inter-medium {
  font-family: Inter-Medium;
}

.font-inter-light {
  font-family: Inter-Light;
}

.font-inter-bold {
  font-family: Inter-Bold;
}

.font-inter-thin {
  font-family: Inter-Thin;
}

.font-inter-semibold {
  font-family: Inter-SemiBold;
}

@font-face {
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Inter-Regular"),
    url("../fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Inter-Medium"),
    url("../fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Light";
  font-style: normal;
  font-weight: normal;
  src: local("Inter-Light"), url("../fonts/Inter-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Inter-Bold"), url("../fonts/Inter-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-Thin";
  font-style: normal;
  font-weight: normal;
  src: local("Inter-Thin"), url("../fonts/Inter-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Inter-SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Inter-Thin"),
    url("../fonts/Inter-SemiBold.ttf") format("truetype");
}

@media (min-width: 240px) and (max-width: 300px) {
  .xs\:w-full-235 {
    width: 235px;
  }

  .xs\:w-full-215 {
    width: 215px;
  }
}

.select-dropdown::-webkit-scrollbar {
  width: var(--spacing-6, 6px);
}

.select-dropdown::-webkit-scrollbar-track {
  background: transparent;
  width: var(--spacing-6, 6px);
}

.select-dropdown::-webkit-scrollbar-thumb {
  background: #dee2e6;
  border-radius: 5px;
}

.select-dropdown:hover::-webkit-scrollbar {
  width: var(--spacing-6, 6px);
  height: 60px;
}

.ReactModal__Content {
  inset: unset !important;
  bottom: 0px !important;
  position: absolute;
}

.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
}
.single-thumb .range-slider__range {
  border-radius: 6px;
}

.range-slider__thumb[data-lower] {
  background: red;
}

input[type="range"] {
  -webkit-appearance: none;
  background: #dee2e6;
  border-radius: 5px;
  background-image: linear-gradient(#575be8, #575be8);
  background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px !important;
  width: 16px !important;
  border-radius: 50%;
  background: #575be8;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-ms-clear {
  display: none;
}

.rmdp-week-day {
  color: #566985 !important;
}

.rmdp-header-values,
.rmdp-day {
  color: #0f2552 !important;
}

.rmdp-ep-arrow {
  display: none;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: #575be8 !important;
  box-shadow: none !important;
  color: white !important;
}

.rmdp-day.rmdp-today span {
  background-color: inherit !important;
  color: inherit !important;
}

.rmdp-day span:hover {
  background-color: #575be8 !important;
  color: white;
  border-radius: 50%;
}

.rmdp-day.rmdp-disabled {
  color: #8798ad !important;
}
.rmdp-day.rmdp-disabled span:hover {
  background-color: transparent !important;
  color: #8798ad !important;
}
.rmdp-day-hidden,
.rmdp-day-hidden:hover span {
  background-color: transparent !important;
}

.rmdp-wrapper {
  border-radius: 11.18px !important;
  border: solid !important;
  border-color: #e4e5e7 !important;
}

.rmdp-header {
  border-bottom: solid !important;
  border-bottom-color: #e4e5e7 !important;
}

.rmdp-shadow {
  box-shadow: none !important;
}

.rmdp-arrow {
  border: solid #e4e5e7 !important;
  border-width: 0 2px 2px 0 !important;
}

.rmdp-arrow-container:hover {
  background-color: #575be8 !important;
  color: white;
}

.rmdp-arrow-container.disabled {
  background-color: transparent !important;
}

._UwsMD {
  height: 48px;
  background-color: #f7f9ff;
  border-radius: 12px;
}

body ._2Eyi4 {
  border: none !important;
}

._2A2xK:not(._3QbTe) {
  color: #dee2e6 !important;
}

body ._2rlJ6 {
  color: #2d3958 !important;
}

.carousel-button-group {
  position: relative;
  left: 79%;
  top: -22.5em;
}

@media (min-width: 1024px) {
  .carousel-button-group {
    left: 82%;
  }
}

button.hover-class:hover img {
  filter: invert(82%) sepia(66%) saturate(14.06%) hue-rotate(176deg)
    brightness(110%) contrast(190%) !important;
}

.css-eci9on-indicatorContainer {
  display: inline !important;
  color: #2d3958 !important;
}

.css-j0ji3h-indicatorSeparator {
  display: none !important;
}

.css-64foih-indicatorSeparator {
  display: none !important;
}

.css-11j1g0h-indicatorContainer
  path[d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"] {
  display: none !important;
  fill: none !important;
  stroke: none !important;
}

.css-u0yspg-indicatorContainer
  path[d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"] {
  display: none !important;
  fill: none !important;
  stroke: none !important;
}

.css-m10bqe-control .css-1jf46ig-control {
  height: inherit !important;
  min-height: none !important;
}

.css-1jf46ig-control {
  height: inherit !important;
  min-height: none !important;
}

.css-tr4s17-option {
  background-color: #575be8 !important;
}

body ._2afkz {
  margin-top: 0 !important;
}

.error-border {
  height: 54px;
  border: solid;
  border-radius: 12px;
  border-color: #d66d77;
}

.floating-label-input-container {
  position: relative;
  height: 52px;
  background-color: #f7f9ff;
  border-radius: 12px;
}

.floating-label {
  position: absolute;
  top: 9px;
  left: 9px;
  pointer-events: none;
  transition: 0.2s ease all;
  color: #d8dde7;
}

.floating {
  left: 0;
  bottom: 0.5em;
  transform: translateY(-100%);
  font-size: 12px;
  color: #2d3958;
}

.sticky-side-section {
  position: sticky !important;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
  top: 115px;
}

.cards-grid {
  display: grid;
  height: fit-content;
  gap: 10px;
  /* gap: 10px 4.215%; */
  width: 100%;
  grid-template-columns: repeat(auto-fit, 360px);
  grid-auto-rows: minmax(min-content, max-content);
  /* justify-content: space-between; */
}

@media (max-width: 1150px) {
  .cards-grid {
    display: grid;
    gap: 10px;
    /* gap: 10px 4.215%; */
    width: 100%;
    grid-template-columns: repeat(auto-fit, 340px);
    grid-auto-rows: minmax(min-content, max-content);
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .cards-grid {
    display: grid;
    gap: 10px;
    /* gap: 10px 4.215%; */
    width: 100%;
    grid-template-columns: repeat(auto-fit, 290px);
    grid-auto-rows: minmax(min-content, max-content);
    justify-content: center;
  }
}

.tags-container {
  display: -webkit-inline-box;
  white-space: nowrap;
  overflow: hidden;
  position: absolute;
  bottom: 17px;
}

.autocomplete input::placeholder {
  color: #d8dde7 !important;
}

.pagination {
  display: flex;
  align-items: center;
  gap: 15px;
}

.previous_page,
.next_page {
  width: 48px;
  height: 47px;
  background: #f7f9ff;
  padding: 12px 16px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
}

.previous_page:hover,
.next_page:hover {
  background: #575be8;
  color: white;
}

.page_number {
  width: 41.77px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.page_number a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 12px;
  text-align: center;
}

.page_number:hover a {
  background: #575be8;
  color: white;
  border-radius: 12px;
}

.active_page {
  width: 41.77px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 12px;
  background: #575be8;
  color: white;
}

.active_page a {
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
}

/*update the datepicker css */
.react-datepicker__day--disabled {
  color: #ccc !important;
}

.react-datepicker__header {
  background-color: white !important;
}

.react-datepicker__day--keyboard-selected {
  color: #000 !important;
  background: transparent !important;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker {
  border-radius: 1rem !important;
}

.react-datepicker__navigation--previous {
  left: 30px !important;
  top: 17px !important;
}

.react-datepicker__navigation--next {
  right: 30px !important;
  top: 17px !important;
}

.react-datepicker__current-month {
  color: var(--Primary-Pressed, #0f2552);
  font-feature-settings: "clig" off, "liga" off;
  font-size: 14px;
  letter-spacing: -0.154px;
  font-family: "Inter-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Inter-Regular"),
    url("../fonts/Inter-Regular.ttf") format("truetype");
  padding-bottom: 20px;
}

.react-datepicker__day-name {
  color: #7e818c !important;
}

.react-datepicker__day {
  color: #0f2552 !important;
}

.react-datepicker__day:hover {
  background-color: white !important;
  border-radius: 100% !important;
  border: 1px solid var(--Accent-grey, #dee2e6);
}

.react-datepicker__day--selected {
  background-color: #575be8 !important;
  border-radius: 100% !important;
  color: white !important;
}

.react-datepicker__day--selected:hover {
  background-color: #575be8 !important;
  border-radius: 100% !important;
  color: white !important;
}

.react-datepicker__day--disabled {
  color: #dee2e6 !important;
}

.react-datepicker__day--disabled:hover {
  border: none !important;
}

.react-datepicker {
  border: 1px solid #e4e5e7 !important;
  padding: 10px !important;
}

.react-datepicker__header {
  border-radius: 1rem !important;
  border-bottom: none !important;
}

.react-datepicker__day-names {
  padding-top: 10px;
  border-top: 1px solid #e4e5e7 !important;
}

.react-datepicker__navigation-icon::before {
  border-color: #2d3958 !important;
  border-width: 2px 2px 0 0 !important;
}

::-webkit-scrollbar {
  display: none;
}

.backgroundImage {
  background-image: url("../img/success-background-img.png");
  background-size: cover;
  background-position: 0 -110px;
  background-repeat: no-repeat;
}

textarea {
  resize: none;
  overflow: scroll;
}
/* Custom Scrollbar Styling */
.scrollbar-custom::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  display: inline;
}

.scrollbar-custom::-webkit-scrollbar-track {
  background-color: #f4f4f4;
  border-radius: 10px;
  padding: 2px;
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background-color: #a4a4a4;
  border-radius: 10px;
  border: 3px solid #f4f4f4;
  height: 81px;
}
