.cuisineCard {
  width: 176px;
  height: 125px;
}

.crowd {
  margin-top: 40px;
}

@media (max-width: 1379px) {
  .cuisineCard {
    width: 140px;
    height: 95px;
  }

  .crowd {
    margin-top: 28px;
  }

  .captionText {
    font-size: 10px;
    line-height: 0.85rem;
    bottom: 5px !important;
  }
}

@media (max-width: 1128px) {
  .cuisineCard {
    width: 110px;
    height: 80px;
    background-size: 40px;
  }
  .captionText {
    font-size: 10px;
    line-height: 0.85rem;
  }
}
