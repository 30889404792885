@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@layer components {
  .btn-blue-accent {
    @apply py-[12px] px-4 bg-catering text-white rounded-md focus:ring-transparent hover:bg-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-opacity-0 font-inter-medium text-sm border font-medium h-[48px];
  }
  .btn-outline {
    @apply px-4 bg-transparent text-catering rounded-md focus:ring-catering hover:bg-slate-100 hover:text-catering focus:outline-none focus:ring-2 focus:ring-opacity-0 font-inter-regular text-sm border border-catering font-medium h-[36px];
  }
  .d-col {
    @apply flex flex-col;
  }
  .d-row {
    @apply flex flex-row;
  }
  .btn-purple {
    @apply py-2 px-4 bg-hh-accent-dark text-sm text-white rounded-md shadow-md hover:text-white outline-none focus:outline-none focus:ring-0 focus:ring-opacity-0 font-inter-regular;
  }
  .btn-purple:hover {
    @apply bg-hh-accent-light;
  }
  .input-light {
    @apply text-xs my-0.5 font-inter-regular rounded-md border border-gray-300 w-full py-2.5 px-3 text-gray-700 focus:outline-none focus:ring-0;
  }
  .input-light-1 {
    @apply text-xs my-0.5 font-inter-regular rounded-md w-full py-2.5 px-3 text-gray-700 focus:outline-none focus:ring-0;
  }
  .input-gray-bg {
    @apply text-sm font-inter-regular rounded-xl bg-background w-full py-2 px-3 focus:outline-none h-12;
  }
  .input-gray-bg-border {
    @apply text-sm font-inter-regular rounded-xl bg-background w-full py-2 px-3 focus:outline-none h-12 border border-dark-gray;
  }
  .btn-style {
    @apply outline-none focus:outline-none focus:ring-0 focus:ring-opacity-0 text-sm;
  }
  .btn-disabled-mode {
    @apply py-[12px] px-4 bg-sidebar-gray text-blue-700 rounded-md focus:ring-transparent hover:bg-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-opacity-0 font-inter-medium text-sm border font-medium h-[48px];
  }
  .btn-disabled {
    @apply py-[12px] px-4 bg-zinc-200 text-white rounded-md focus:ring-transparent focus:outline-none focus:ring-2 focus:ring-opacity-0 font-inter-medium text-sm border font-medium h-[48px] hover:bg-zinc-400;
  }
  .btn-white {
    @apply py-[10px] px-[10px] bg-white text-catering rounded-md focus:ring-transparent hover:bg-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-opacity-0 font-inter-medium text-[14px] border font-medium h-[48px] font-inter-medium border-none;
  }
  .btn-light {
    @apply py-2 px-4 border-gray-200 border bg-white text-sm text-primary-black rounded-md hover:text-white focus:outline-none focus:ring-0 focus:ring-opacity-0 font-inter-regular;
  }
  .btn-light:hover {
    @apply hover:bg-hh-accent-light;
  }
  .btn-light-gray {
    @apply bg-light-gray text-catering w-[220px] h-[46px] rounded-md font-inter-medium text-[14px] hover:shadow-sm;
  }
}
